<template>
  <div class="toolbar">
    <div class="dropdown">
      <router-link to="/chat/" class="zixun">
        <i class="iconfont">&#xe78f;</i
        ><span class="iconfont-text">在线咨询</span>
      </router-link>
      <div class="dropdown-bar">
        <h5>您也可以拨打客服电话：</h5>
        <strong v-if="system">{{ system.system_phone }}</strong>
        <p>（08:00-次日01:00）</p>
      </div>
    </div>
    <ul>
      <div :class="{ drop: true, transform: isHover }">
        <img
          src="http://www.lipin.com/template/static/images/qrcode/weixin.png"
          alt=""
        />

        <p>扫码关注微信公众号</p>
      </div>
      <li
        class="weixin"
        @mouseover="isHover = true"
        @mouseout="isHover = false"
      >
        <div class="icon">
          <i class="iconfont">&#xf0106; </i>
          <span>关注<br />微信</span>
        </div>
      </li>

      <li>
        <div class="icon">
          <i class="iconfont">&#xe6cf; </i>
          <span>建议<br />反馈</span>
        </div>
      </li>

      <li @click="goTopHead">
        <div class="icon">
          <i class="iconfont">&#xe64a; </i>
          <span>回到<br />顶部</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Toolbar",

  data() {
    return {
      isHover: false,
    };
  },
  computed: {
    ...mapState("system", ["system"]),
  },
  mounted() {},

  methods: {
    goTopHead() {
      document.documentElement.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>

<style lang="less" scoped>
// .toolbar .weixin:hover + .drop {
//   transform: translateX(0px);
// }
.transform {
  transform: translateX(0px) !important;
}
.toolbar {
  position: fixed;
  right: 0;
  bottom: 100px;
  border-radius: 6px 0 6px 6px;
  border-color: rgba(255, 255, 255, 0);
  background-color: #fff;
  box-shadow: 0 5px 35px -3px rgba(50, 50, 50, 0.2);
  z-index: 3000;

  .drop {
    width: 140px;
    height: 170px;
    padding: 10px;
    box-sizing: border-box;
    position: absolute;
    left: -160px;
    z-index: 1;
    background-color: #fff;
    top: 0;
    box-shadow: 0 5px 35px -3px rgba(50, 50, 50, 0.2);
    border-radius: 6px;
    transform: translateX(240px);
    transition: all 0.3s;
    img {
      width: 117px;
      height: 117px;
    }
    p {
      font-size: 12px;
      color: #999;
      margin: 0;
      margin-top: 10px;
      text-align: center;
    }
  }

  .dropdown {
    margin-top: 10px;
    position: relative;
    height: 96px;
    &:hover {
      .dropdown-bar {
        transform: translateX(0);
      }
    }
    .dropdown-bar {
      right: 72px;
      height: 96px;
      padding: 11px 50px 11px 30px;
      top: 50%;
      width: 234px;
      margin-top: -48px;
      border-color: rgba(255, 255, 255, 0);
      background-color: #fff;
      box-shadow: 0 5px 35px -3px rgba(50, 50, 50, 0.2);
      position: absolute;
      z-index: 9;
      font-size: 14px;
      border-radius: 6px;
      pointer-events: none;
      transform: translateX(300px);
      // visibility: hidden;
      transition: all 0.3s;
      h5 {
        color: #333;
        font-size: 14px;
        margin: 0;
      }
      strong {
        color: #ff5c38 !important;
        font-size: 22px;
        font-weight: 400;
      }
      p {
        font-size: 12px;
      }
    }
    .iconfont-text {
      color: #fff;
      font-size: 14px;
      position: relative;
      top: 15px;
    }
    .iconfont {
      font-size: 40px;
      position: absolute;
      top: 8px;
    }
    a {
      position: absolute;
      top: 0;
      right: 0;
      top: -1px\9;
      right: -1px\9;
      display: flex;
      z-index: 10;
      width: 96px;
      height: 96px;
      overflow: hidden;
      color: #fff;
      text-align: center;
      border-radius: 24px 0 0 24px;
      transition: 0.3s;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.8),
        0 3px 30px -3px rgba(0, 133, 254, 0.6);
      background: linear-gradient(to right, #00aaff 0%, #0085fe 100%);
    }
  }
  ul {
    position: relative;
    z-index: 1000;
    li {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      cursor: pointer;
      width: 60px;
      height: 60px;
      position: relative;
      z-index: 100;
      .icon {
        width: 60px;
        height: 120px;
        box-sizing: border-box;
        overflow: hidden;
        box-sizing: border-box;
        position: relative;
        transition: all 0.3s;
        span {
          width: 60px;
          height: 60px;
          transition: all 0.3s;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #007dfe;
        }
        &:hover {
          margin-top: -60px;
        }
      }

      .iconfont {
        font-size: 24px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      // .bottom {
      //   width: 64px;
      //   padding-left: 4px;
      //   height: 60px;
      //   box-sizing: border-box;
      //   // transform: translateY(60px);
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   &:hover {
      //     span {
      //       color: #007dfe;
      //     }
      //   }
      //   span {
      //     color: #666;
      //     cursor: pointer;
      //   }
      // }
    }
  }
}
</style>