<template>
    <div>
        <Header v-if="!$route.meta.isHideHAndF" />
        <router-view />
        <Footer v-if="!$route.meta.isHideHAndF" />
        <Toolbar />
        <!-- <div class="verify" @click="$router.push('/verify')">
      <i class="iconfont">&#xe69e;</i>
      <span>卡密核验</span>
    </div> -->
    </div>
</template>
<script>
import Header from '@/components/Header/index';
import Footer from '@/components/Footer/index_new';
import Toolbar from '@/components/Toolbar/';
import { mapActions } from 'vuex';
export default {
    name: 'App',
    components: {
        Footer,
        Header,
        Toolbar,
    },
    data() {
        return {};
    },
    mounted() {
        this.getTypeList();
    },
    methods: {
        ...mapActions('types', ['getTypeList']),
        // removeSearch(event) {
        //   if (document.querySelector(".dropdown")) {
        //     if (event.target != document.querySelector(".dropdown")) {
        //       document.querySelector(".dropdown").classList.remove("active");
        //     }
        //   }
        // },
    },
};
</script>
<style lang="less">
.verify {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    background: linear-gradient(to right, rgba(252, 217, 222, 100%), rgba(236, 188, 204, 100%), rgba(166, 141, 186, 100%));
    position: fixed;
    bottom: 400px;
    right: 0;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    z-index: 1000;
    i {
        font-size: 28px;
    }
}
</style>
