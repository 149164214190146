function getUrl(url) {
    return 'http://pic.pusite.icu/' + url;
}

function group(array, subGroupLength) {
    var index = 0;
    var newArray = [];

    while (index < array.length) {
        newArray.push(array.slice(index, (index += subGroupLength)));
    }

    return newArray;
}

function debounce(fn, wait = 1000) {
    let timer;
    return function () {
        let context = this;
        let args = arguments;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
            fn.apply(context, args);
        }, wait);
    };
}

/**
 * 函数节流
 * 触发事件立即执行，但在n秒内连续触发则不执行
 */
function throttle(fn, wait = 1000) {
    let timer;
    return function () {
        if (timer != null) return;
        let context = this;
        let args = arguments;
        fn.apply(context, args);
        timer = setTimeout(() => {
            timer = null;
        }, wait);
    };
}

export { getUrl, group, debounce, throttle };
