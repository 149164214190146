<template>
    <div>
        <div class="header">
            <div class="header-top">
                <div class="main-n">
                    <div class="header-top-left">
                        <div class="logo">
                            <img v-lazy="getUrl(system?.system_logo_black)" alt="" />
                        </div>
                        <b>{{ system?.system_name }} 欢迎您！</b>
                    </div>

                    <div class="header-top-right">
                        <ul>
                            <li>
                                <a href="/#/article/39">关于我们</a>
                                <a-divider type="vertical" />
                            </li>
                            <li>
                                <a href="/#/article/question">常见问题</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="header-bottom">
                <div class="main-n">
                    <div class="header-bottom-left">
                        <ul>
                            <li>
                                <a href="">首页</a>
                            </li>
                            <li v-for="item in typeList" :key="item.id">
                                <router-link :to="'/type/' + item.id">{{ item.type_name }}</router-link>
                            </li>
                        </ul>
                    </div>
                    <div class="header-bottom-right">
                        <button @click="$router.push('/publish')">发布信息</button>

                        <button v-if="!userInfo" @click="$router.push('/login')">登录</button>

                        <button v-else @click="$router.push('/my')">个人中心</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'Header',

    data() {
        return {};
    },
    computed: {
        ...mapState('types', ['typeList']),
        ...mapState('system', ['system']),
        ...mapState('user', ['userInfo']),
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
.header {
    height: 140px;
    background-color: #fff;
    .header-top {
        height: 60px;
        border-bottom: 1px solid #ddd;
        .main-n {
            display: flex;
            align-items: center;
            height: 100%;
            justify-content: space-between;
            .logo {
                height: 40px;
                width: 170px;
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .header-top-left {
                display: flex;
                align-items: center;
                b {
                    color: #333;
                }
            }
            .header-top-right {
                ul {
                    display: flex;
                    li {
                        a {
                            color: #666;
                        }
                    }
                }
            }
        }
    }
    .header-bottom {
        height: 80px;
        border-bottom: 1px solid #ddd;
        .main-n {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;
            .header-bottom-left {
                ul {
                    display: flex;
                    li {
                        margin-right: 40px;
                        a {
                            font-size: 20px;
                            color: #000;
                            font-weight: 600;
                        }
                    }
                }
            }
            .header-bottom-right {
                button {
                    width: 82px;
                    height: 42px;
                    color: #333;
                    border: 1px solid #aaa;
                    background: #fff;
                    border-radius: 3px;
                    margin-left: 20px;
                    &:first-of-type {
                        background-image: linear-gradient(to right, #ded4ff, #ebaeae);
                        color: #fff;
                        width: 100px;
                        border: 0;
                    }
                }
            }
        }
    }
}
</style>
