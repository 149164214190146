import request from '@/utils/request';
export const reqUserInfo = () => request.post('/getUserInfoByToken');
export const reqLogout = () => request.post('/logout');
export const reqLogin = params => {
    return request.post('/login', params);
};

export function reqRegister(params) {
    return request.post('/register', params);
}
export function reqGetArticleHome() {
    return request.post('/reqGetArticleHome');
}

export function reGetAllTypeList() {
    return request.post('/getAllTypeList');
}

export function reqGetAllTypeListLimit() {
    return request.post('/getAllTypeListLimit');
}

export function reqUploadImage(params) {
    return request.post('/uploadImage', params);
}

export function reqTextareaImageUpload(params) {
    return request.post('/reqTextareaImageUpload', params);
}
export function reqGetSearch(params) {
    return request.post('/reqGetSearch', params);
}

export function reqRegisterCode(params) {
    return request.post('/send', params);
}
export const reqGetSystem = () => request.post('/getSiteInfo');

export function reqAddInfo(params) {
    return request.post('/reqAddInfo', params);
}

// 上传表单
export function reqMessageSubmit(params) {
    return request.post('/messageSubmit', params);
}
//最新动态
export const reqGetDynamic = () => request.post('/getDynamic');
export const reqGetNew = () => request.post('/reqGetNew');

// 获取轮播
export const reqGetSlide = () => request.post('/getSlide');

// 获取用户是否唯一
export function reqRegisterUserNameIsUnique(params) {
    return request.post('/reqRegisterUserNameIsUnique', params);
}
// 获取 类型下面的
export function reqGetTypeList(params) {
    return request.post('/reqGetTypeList', params);
}

export function reqGetRecommendHome() {
    return request.post('/reqGetRecommendHome');
}
export function reqGetMessageByType() {
    return request.post('/reqGetMessageByType');
}

export function reqTypeList() {
    return request.post('/getTypeList');
}

//获取首页类型下面的数据
export function reqGetTypeMessageCondition(params) {
    return request.post('/reqGetTypeMessageCondition', params);
}

export function reqGetTypeListById(params) {
    return request.post('/reqGetTypeListById', params);
}

export const reqGetMessageById = id => request.post('/reqGetMessageById', id);
export const reqGetCompanyById = id => request.post('/reqGetCompanyById', id);
export const reqGetTypeMessage = () => request.post('/reqGetTypeMessage');
// 更新用户信息
export function reqUpdateUserInfo(params) {
    return request.post('/reqUpdateUserInfo', params);
}

export const reqGetMyPublish = () => request.post('/reqGetMyPublish');
export const reqDelMyPublishMessage = id => request.post('/reqDelMyPublishMessage', id);
export function reqSearch(params) {
    return request.post('/reqSearch', params);
}

export const reqGetRecommend = () => request.post('/reqGetRecommend');
export function reqGetArticleById(params) {
    return request.post('/reqGetArticleById', params);
}

export function reqGetDeafultArticle(params) {
    return request.post('/reqGetDeafultArticle', params);
}

export function reqGetQuestionArticle(params) {
    return request.post('/reqGetQuestionArticle', params);
}

export function reqGetNoticeArticle(params) {
    return request.post('/reqGetNoticeArticle', params);
}
