export default [
    {
        path: '/',
        name: 'Home',
        meta: {
            isHideHAndF: false,
            isHome: true,
        },
        component: () => import('@/views/Home/index.vue'),
    },

    {
        path: '/type/:id',
        name: 'Type',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Type/index'),
    },
    {
        path: '/info/:id',
        name: 'Info',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Info/index_new'),
    },

    {
        path: '/company/:id',
        name: 'Company',
        meta: {
            isHideHAndF: true,
            isHome: false,
        },
        component: () => import('@/views/Company'),
    },
    {
        path: '/verify',
        name: 'Verify',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Verify/'),
    },

    {
        path: '/chat',
        name: 'Chat',
        meta: {
            isHideHAndF: true,
            isHome: false,
        },
        component: () => import('@/views/Chat/'),
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Login/login'),
    },
    {
        path: '/register',
        name: 'Register',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Login/register'),
    },

    {
        path: '/publish',
        name: 'Publish',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Publish/'),
    },
    {
        path: '/article',
        meta: {
            isHideHAndF: false,
            isHome: false,
        },
        component: () => import('@/views/Article/'),
        children: [
            {
                path: 'notice',
                name: 'Notice',
                meta: {
                    keepalive: true,
                    isHome: false,
                    isHideHAndF: false,
                },
                component: () => import('@/views/Article/components/Notice/'),
            },
            {
                path: 'question',
                name: 'Question',
                meta: {
                    keepalive: true,
                    isHome: false,
                    isHideHAndF: false,
                },
                component: () => import('@/views/Article/components/Question/'),
            },
            {
                path: ':id',
                name: 'Article',
                meta: {
                    keepalive: true,
                    isHome: false,
                    isHideHAndF: false,
                },
                component: () => import('@/views/Article/components/Default/'),
            },
        ],
    },

    {
        path: '/my',
        name: 'my',
        component: () => import('@/views/my'),
        children: [
            {
                //欢迎页
                path: '',
                name: 'me',
                meta: {
                    title: '我的资料',
                    keepalive: true,
                    isHome: false,
                },
                component: () => import('@/views/my/components/me.vue'),
            },
            {
                //欢迎页
                path: '/myPublish',
                name: 'myPublish',
                meta: {
                    title: '我的发布',
                    keepalive: true,
                    isHome: false,
                },
                component: () => import('@/views/my/components/myPublish.vue'),
            },
            {
                //欢迎页
                path: '/mypay',
                name: 'mypay',
                meta: {
                    title: '充值会员',
                    keepalive: true,
                    isHome: false,
                },
                component: () => import('@/views/my/components/pay.vue'),
            },
        ],
    },
];
