<template>
    <footer>
        <div class="footer-top">
            <div class="main">
                <div class="footer-top-left">
                    <div class="logo">
                        <img v-if="system" v-lazy="getUrl(system.system_logo_white)" alt="" />
                    </div>
                    <p v-if="system">
                        {{ system.system_desc }}
                    </p>
                </div>
                <div class="footer-top-mid">
                    <ul>
                        <li v-for="item in typeList" :key="item.id">
                            <router-link :to="{ name: 'Type', params: { id: item.id } }">{{ item.type_name }}</router-link>
                            <a-divider type="vertical" />
                        </li>
                        <!-- <li><a href="/#/type/46">购物卡回收</a> <a-divider type="vertical" /></li>
                        <li><a href="/#/type/48">奢侈品回收</a><a-divider type="vertical" /></li>
                        <li><a href="/#/type/52">今日行情</a></li> -->
                    </ul>

                    <dl v-if="system">
                        <dd>商务合作：{{ system.system_email }}</dd>
                        <dd>联系邮箱：{{ system.system_email }}</dd>
                        <dd>联系地址：{{ system.system_address }}</dd>
                        <dd>联系电话：{{ system.system_phone }}</dd>
                    </dl>
                </div>
                <!-- <div class="footer-top-right">
          <ul>
            <li>
              <img src="@/assets/weixin1.png" alt="" />
              <p>关注微信公众号</p>
            </li>

            <li>
              <img src="@/assets/app_code.jpg" alt="" />
              <p>卡卡礼品网小程序</p>
            </li>
          </ul>
        </div> -->
            </div>
        </div>
        <div class="footer-bottom" v-if="system">
            <p v-if="system">{{ system.system_copyright }}</p>
            <p style="margin-left: 20px">
                备案号：<a href="http://beian.miit.gov.cn/" target="_blank">{{ system.system_icp }}</a>
            </p>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'FooterNew',

    data() {
        return {};
    },
    computed: {
        ...mapState('system', ['system']),
        ...mapState('types', ['typeList']),
    },
    mounted() {},

    methods: {},
};
</script>

<style lang="less" scoped>
footer {
    height: 325px;
    background: #121212;
    color: #fff;
    .main {
        position: relative;
        display: flex;
    }
    .footer-top {
        padding: 40px 0;
        box-sizing: border-box;
        background-color: #242529;
        .footer-top-left {
            width: 300px;
            .logo {
                margin-bottom: 20px;
            }
            p {
                max-height: 110px;
                line-height: 22px;
                font-size: 12px;
                color: #888;
                overflow: hidden;
            }
        }

        .footer-top-mid {
            margin: 0 75px;
            ul {
                display: flex;
                margin-bottom: 30px;
                margin-top: 30px;
                a {
                    font-size: 14px;
                    color: #ccc;
                }
            }

            dl {
                dd {
                    font-size: 12px;
                    color: #888;
                }
            }
        }

        .footer-top-right {
            position: absolute;
            right: 0;
            ul {
                display: flex;
                li {
                    margin-right: 20px;
                    img {
                        width: 100px;
                        height: 100px;
                        margin-bottom: 15px;
                    }
                    p {
                        height: 20px;
                        line-height: 20px;
                        color: #888;
                        text-align: center;
                        font-size: 12px;
                        overflow: hidden;
                    }
                }
            }
        }
    }
    .footer-bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        height: calc(100% - 283px);
        p {
            margin: 0;
            color: #565656;
        }
    }
}
</style>
